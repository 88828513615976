import { useIsMinWidth } from "@app/design-system";
import type { ElementType } from "@kablamo/kerosene";
import type mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import { useOptionalVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import type { MapboxMouseEvent } from "../types";
import type { InteractiveLayerMap } from "./MapInteractions";
import type { FeatureInteractionProperties } from "./types";
import type {
  ActivateInteractionStateParams,
  MapInteractionState,
} from "./useMapInteractionState";

interface UseMapInteractionEventsParams {
  layers: InteractiveLayerMap;
  interactionState: MapInteractionState<FeatureInteractionProperties>;
}

interface GetActivateInteractionStateParams {
  event: MapboxMouseEvent | maplibregl.MapLayerEventType["mousemove"];
  layers: InteractiveLayerMap;
}

const getActivateInteractionStateParams = ({
  event,
  layers,
}: GetActivateInteractionStateParams): ActivateInteractionStateParams<FeatureInteractionProperties> | null => {
  const { features } = event;

  const feature =
    // TODO: This `as` assertion should no longer be required in TS5.2
    (
      features as Array<ElementType<NonNullable<typeof features>>> | undefined
    )?.find((data) => layers.has(data.layer.id));
  if (!feature) return null;

  const layer = layers.get(feature.layer.id);
  if (!layer) return null;

  const properties = layer.getPropertiesFromFeature(feature, event);
  if (!properties) return null;

  return { layerId: layer.layerId, properties };
};

const useMapInteractionEvents = ({
  layers,
  interactionState: mapInteractionState,
}: UseMapInteractionEventsParams) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");
  const { lib, map } = useUnsafeMapContext();

  // If the map interactions are happening in a context where there could be
  // an active map tool, we want to check whether the tool is active before
  // applying our map events in order to prevent conflicting with the tool
  // behaviour.
  const visualiser = useOptionalVisualiser();
  const activeTool = visualiser?.visualiserState.activeTool;

  const { activateHoverState, deactivateHoverState, activateClickState } =
    mapInteractionState;

  useEffect(() => {
    if (!isTabletLandscapeAndAbove || activeTool) return;

    const onMouseMove = (
      event: mapboxgl.MapMouseEvent | maplibregl.MapLayerEventType["mousemove"],
    ) => {
      if (map) {
        map.getCanvas().style.cursor = "pointer";
      }

      const params = getActivateInteractionStateParams({ event, layers });
      if (!params) return;

      activateHoverState(params);
    };

    const onMouseLeave = () => {
      if (map) {
        map.getCanvas().style.cursor = "";
      }

      deactivateHoverState();
    };

    const layerIds = [...layers.keys()];

    if (lib === "mapbox") {
      map?.on("mousemove", layerIds, onMouseMove);
      map?.on("mouseleave", layerIds, onMouseLeave);
    } else {
      layerIds.forEach((layerId) => {
        map?.on("mousemove", layerId, onMouseMove);
        map?.on("mouseleave", layerId, onMouseLeave);
      });
    }

    return () => {
      if (lib === "mapbox") {
        map?.off("mousemove", layerIds, onMouseMove);
        map?.off("mouseleave", layerIds, onMouseLeave);
      } else {
        layerIds.forEach((layerId) => {
          map?.off("mousemove", layerId, onMouseMove);
          map?.off("mouseleave", layerId, onMouseLeave);
        });
      }
    };
  }, [
    activateHoverState,
    activeTool,
    deactivateHoverState,
    isTabletLandscapeAndAbove,
    layers,
    lib,
    map,
  ]);

  useEffect(() => {
    if (activeTool) return;

    const onClick = (
      event: MapboxMouseEvent | maplibregl.MapLayerEventType["click"],
    ) => {
      const params = getActivateInteractionStateParams({ event, layers });
      if (!params) return;

      activateClickState(params);
    };

    const layerIds = [...layers.keys()];

    if (lib === "mapbox") {
      map?.on("click", layerIds, onClick);
    } else if (lib === "maplibre") {
      layerIds.forEach((layerId) => {
        map?.on("click", layerId, onClick);
      });
    }

    return () => {
      if (lib === "mapbox") {
        map?.off("click", layerIds, onClick);
      } else if (lib === "maplibre") {
        layerIds.forEach((layerId) => {
          map?.off("click", layerId, onClick);
        });
      }
    };
  }, [activateClickState, activeTool, layers, lib, map]);
};

export default useMapInteractionEvents;
