import { useEffect } from "react";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import type { MapboxMouseEvent } from "../types";
import type { InteractiveLayerMap } from "./MapInteractions";
import type { FeatureInteractionProperties } from "./types";
import type { MapInteractionState } from "./useMapInteractionState";

interface UsePersistentMapInteractionEventsParams {
  persistentInteractiveLayers: InteractiveLayerMap;
  popupInteractiveLayers: InteractiveLayerMap;
  interactionState: MapInteractionState<FeatureInteractionProperties>;
}

const getLayerIds = (interactiveLayers: InteractiveLayerMap): string[] => {
  return [...interactiveLayers.entries()]
    .filter(([, layer]) => layer.element === "feature")
    .map(([id]) => id);
};

/**
 * Used for tracking map interaction state for a persistent element: one which
 * should not be closed by clicking other popups on the map. For example,
 * using an interaction clicked state to show and hide a persistent UI element
 * like the SocialDrawer
 *
 */
const usePersistentMapInteractionEvents = ({
  persistentInteractiveLayers,
  popupInteractiveLayers,
  interactionState,
}: UsePersistentMapInteractionEventsParams) => {
  const { map } = useUnsafeMapContext();

  const { clickedState, deactivateClickState } = interactionState;

  useEffect(() => {
    if (!clickedState.isActive || !map) {
      return;
    }

    const onClick = (event: MapboxMouseEvent) => {
      // Gather all the layer IDs that are currently interactive
      const layerIds = [
        ...getLayerIds(popupInteractiveLayers),
        ...getLayerIds(persistentInteractiveLayers),
      ];

      const features = map.queryRenderedFeatures(event.point, {
        layers: layerIds,
      });

      // If we have clicked on a feature belonging to an interactive popup layer
      // or another interactive persistent layer, don't deactivate the
      // persistent state
      if (features.length) return;

      deactivateClickState();
    };

    map.on("click", onClick);

    return () => {
      map.off("click", onClick);
    };
  }, [
    clickedState.isActive,
    deactivateClickState,
    persistentInteractiveLayers,
    popupInteractiveLayers,
    map,
  ]);
};

export default usePersistentMapInteractionEvents;
