import type { Feature } from "geojson";
import React, { createContext, useCallback, useMemo, useState } from "react";
import type { MapboxMouseEvent } from "../types";
import type {
  FeatureInteractionProperties,
  InteractiveMapElement,
  MapInteractionType,
} from "./types";
import useMapInteractionEvents from "./useMapInteractionEvents";
import useMapInteractionState, {
  type MapInteractionState,
} from "./useMapInteractionState";
import usePersistentMapInteractionEvents from "./usePersistentMapInteractionEvents";

export type GetPropertiesFromFeatureFn<P extends FeatureInteractionProperties> =
  (
    feature: Feature,
    event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
  ) => P | null;

export interface InteractiveLayer<P extends FeatureInteractionProperties> {
  element: InteractiveMapElement;
  getFeatureId?: (properties: P) => string;
  getPropertiesFromFeature: GetPropertiesFromFeatureFn<P>;
  layerId: string;
  sourceId?: string;
}

export type InteractiveLayerMap = Map<
  string,
  InteractiveLayer<FeatureInteractionProperties>
>;

interface RegisterMapInteractionParams {
  layer: InteractiveLayer<FeatureInteractionProperties>;
  type: MapInteractionType;
}

interface UnregisterMapInteractionParams {
  layerId: string;
  type: MapInteractionType;
}

type RegisterMapInteractionFn = (params: RegisterMapInteractionParams) => void;
type UnregisterMapInteractionFn = (
  params: UnregisterMapInteractionParams,
) => void;

interface MapInteractionsContextValue {
  persistentInteractionState: MapInteractionState<FeatureInteractionProperties>;
  popupInteractionState: MapInteractionState<FeatureInteractionProperties>;
  register: RegisterMapInteractionFn;
  unregister: UnregisterMapInteractionFn;
}

export const MapInteractionsContext = createContext<
  MapInteractionsContextValue | undefined
>(undefined);

interface MapInteractionsProviderProps {
  children?: React.ReactNode;
}

const MapInteractionsProvider = ({
  children,
}: MapInteractionsProviderProps) => {
  const [persistentInteractiveLayers, setPersistentInteractiveLayers] =
    useState<InteractiveLayerMap>(() => new Map());
  const [popupInteractiveLayers, setPopupInteractiveLayers] =
    useState<InteractiveLayerMap>(() => new Map());

  const register: RegisterMapInteractionFn = useCallback(({ layer, type }) => {
    if (type === "popup") {
      setPopupInteractiveLayers((prev) => {
        const map = new Map([...prev]);
        map.set(layer.layerId, layer);
        return map;
      });
    } else {
      setPersistentInteractiveLayers((prev) => {
        const map = new Map([...prev]);
        map.set(layer.layerId, layer);
        return map;
      });
    }
  }, []);

  const unregister: UnregisterMapInteractionFn = useCallback(
    ({ layerId, type }) => {
      if (type === "popup") {
        setPopupInteractiveLayers((prev) => {
          const map = new Map([...prev]);
          map.delete(layerId);
          return map;
        });
      } else {
        setPersistentInteractiveLayers((prev) => {
          const map = new Map([...prev]);
          map.delete(layerId);
          return map;
        });
      }
    },
    [],
  );

  // Popups should be able to be opened and closed while a persistent element
  // like the Social Drawer is open
  const popupInteractionState =
    useMapInteractionState<FeatureInteractionProperties>();
  const persistentInteractionState =
    useMapInteractionState<FeatureInteractionProperties>();

  useMapInteractionEvents({
    layers: popupInteractiveLayers,
    interactionState: popupInteractionState,
  });

  useMapInteractionEvents({
    layers: persistentInteractiveLayers,
    interactionState: persistentInteractionState,
  });

  usePersistentMapInteractionEvents({
    persistentInteractiveLayers,
    popupInteractiveLayers,
    interactionState: persistentInteractionState,
  });

  const mapInteractionsProviderValue = useMemo(
    () => ({
      persistentInteractionState,
      popupInteractionState,
      register,
      unregister,
    }),
    [persistentInteractionState, popupInteractionState, register, unregister],
  );

  return (
    <MapInteractionsContext.Provider value={mapInteractionsProviderValue}>
      {children}
    </MapInteractionsContext.Provider>
  );
};

export default MapInteractionsProvider;
