import type { LngLatLike } from "mapbox-gl";

export type InteractiveMapElement = "feature" | "marker";

export type MapInteractionType = "popup" | "persistent";

export enum InteractionStateType {
  CLICKED = "clicked",
  HOVERED = "hovered",
}

export interface FeatureInteractionProperties {
  featureId?: string;
  lngLat: LngLatLike;
}

type ActiveFeatureInteractionState<P extends FeatureInteractionProperties> = {
  isActive: true;
  layerId: string;
  properties: P;
};

type InactiveFeatureInteractionState<P extends FeatureInteractionProperties> = {
  isActive: false;
  layerId: string | null;
  properties: P | null;
};

export type FeatureInteractionState<P extends FeatureInteractionProperties> = {
  id: string;
} & (ActiveFeatureInteractionState<P> | InactiveFeatureInteractionState<P>);
